import { useProfile } from "../api/useAuth";
import Layout from "../components/Layout";

const Profile = () => {
  const profile = useProfile();

  return (
    <Layout>
      <h1 className="text-2xl">Profile settings</h1>
      <div className="mt-8">
        {profile.isSuccess && <p>Email: {profile.data.email}</p>}
        {profile.isError && <p>Something went wrong...</p>}
      </div>
    </Layout>
  );
};

export default Profile;
