import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useProfile } from "../api/useAuth";
import Layout from "./Layout";

const RequireLogin = ({ children }: { children: ReactNode }) => {
  const profile = useProfile();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      profile.isError ||
      (profile.isSuccess &&
        (!profile.data || (profile.data && profile.data.email === "")))
    ) {
      navigate("/");
    }
  }, [profile, navigate]);

  if (profile.isSuccess) {
    return <>{children}</>;
  }
  return <Layout>Loading...</Layout>;
};

export default RequireLogin;
