import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./pages/App";
import Welcome from "./pages/Welcome";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import RequireLogin from "./components/RequireLogin";
import Profile from "./pages/Profile";
import Beach from "./pages/Beach";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <Welcome />,
  },
  {
    path: "/desk",
    element: (
      <RequireLogin>
        <App />
      </RequireLogin>
    ),
  },
  {
    path: "/profile",
    element: (
      <RequireLogin>
        <Profile />
      </RequireLogin>
    ),
  },
  {
    path: "/beach",
    element: (
      <RequireLogin>
        <Beach />
      </RequireLogin>
    ),
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);
