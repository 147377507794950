import { useState } from "react";
import { useQueryClient } from "react-query";
import { NavigateFunction } from "react-router-dom";
import { backendUrl } from "../utils/environment";

const ProfileDropdown = ({ navigate }: { navigate: NavigateFunction }) => {
  const queryClient = useQueryClient();
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div className="flex flex-col">
      <button
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
        className="border border-[#6C3408] py-2 px-4"
      >
        Profile
      </button>
      <div className="relative">
        {showDropdown && (
          <div className="absolute right-0 bg-[#fbf1a0] w-fit border border-[#6C3408] [&>*]:w-full [&>:not(:last-child)]:border-[#6C3408] [&>:not(:last-child)]:border-b-2">
            <button
              onClick={() => {
                navigate("/profile");
              }}
              className="py-2 px-4"
            >
              Settings
            </button>
            <button
              onClick={() => {
                document.cookie =
                  "auth-session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                window.location.href = `${backendUrl()}/auth/logout`;
                queryClient.invalidateQueries("profile");
              }}
              className="py-2 px-4"
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileDropdown;
