import { ReactNode } from "react";
import Header from "./Header";

const Layout = ({
  children,
  hideHeader,
}: {
  children: ReactNode;
  hideHeader?: boolean;
}) => {
  return (
    <>
      {!hideHeader && <Header />}

      <div className="mx-4 md:mx-auto md:max-w-3xl py-12 text-xl">
        {children}
      </div>
    </>
  );
};

export default Layout;
