import { useQuery } from "react-query";
import { backendUrl } from "../utils/environment";

export type User = {
  email: string;
  name: string;
};

export const useProfile = () => {
  return useQuery<User, Error>(
    "profile",
    async () => {
      const res = await fetch(`${backendUrl()}/auth/info`, {
        credentials: "include",
        mode: "cors",
      }).then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        return "";
      });
      return res;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};
