import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useProfile } from "../api/useAuth";
import Layout from "../components/Layout";
import { backendUrl } from "../utils/environment";

const Welcome = () => {
  const profile = useProfile();
  const navigate = useNavigate();

  useEffect(() => {
    if (profile.isSuccess && profile.data && profile.data.email !== "") {
      navigate("/desk");
    }
  }, [profile, navigate]);

  return (
    <Layout hideHeader>
      <div className="flex flex-col items-center pt-8">
        <h1 className="text-center text-4xl">Welcome</h1>
        <button
          onClick={() => {
            window.location.href = `${backendUrl()}/auth/login`;
          }}
          className="border border-[#6C3408] py-2 px-4 mt-12"
        >
          Login
        </button>
      </div>
    </Layout>
  );
};

export default Welcome;
