import { FormEvent, useState } from "react";
import Layout from "../components/Layout";
import { backendUrl } from "../utils/environment";

const Beach = () => {
  const [message, setMessage] = useState<string>("");
  const maxMessageLength = 250;

  const sendMessage = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetch(`${backendUrl()}/message/send`, {
      method: "POST",
      body: JSON.stringify(message),
      mode: "cors",
      credentials: "include",
    })
      .then(() => setMessage(""))
      .catch((error) => console.error(error));
  };

  return (
    <Layout>
      <form onSubmit={(e) => sendMessage(e)}>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          maxLength={maxMessageLength}
          className="resize-none w-full rounded-md h-64 border p-4"
          placeholder="Got something in your mind?"
        />
        <p className="mt-2">
          Characters left: {message.length}/{maxMessageLength}
        </p>

        <button className="border border-[#6C3408] py-2 px-4 mt-4">Send</button>
      </form>
    </Layout>
  );
};

export default Beach;
