import { useNavigate } from "react-router-dom";
import ProfileDropdown from "./ProfileDropdown";

const Header = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-row justify-between py-4 mx-4 md:mx-auto md:max-w-3xl">
      <button
        onClick={() => {
          navigate("/desk");
        }}
        className=" border border-[#6C3408] py-2 px-4"
      >
        Open message
      </button>
      <button
        onClick={() => {
          navigate("/beach");
        }}
        className="border border-[#6C3408] py-2 px-4"
      >
        Send message
      </button>
      <ProfileDropdown navigate={navigate} />
    </div>
  );
};

export default Header;
